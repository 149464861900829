<template>
  <v-dialog v-model="dialog" width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="info" outlined v-bind="attrs" v-on="on" small>
        Ver documentos
        <v-icon class="ml-2" small>mdi-file-download</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5">
        <v-row class="align-center">
          <v-col> Documentos firmados biométricamente</v-col>
          <v-col cols="auto">
            <v-btn fab small depressed @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" v-if="loading">
            <v-skeleton-loader type="paragraph"></v-skeleton-loader>
            <v-skeleton-loader type="paragraph"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" class="mt-5">
            <v-row>
              <v-col
                cols="12"
                v-for="(doc, i) in docs"
                :key="doc.id"
                :class="{ 'grey lighten-3': i % 2 !== 0 }"
                class="d-flex justify-space-between"
              >
                <div>{{ doc.title }}</div>
                <v-btn
                  @click="downloadSessionFile(doc.id)"
                  :loading="downloadingFile[doc.id]"
                  color="primary"
                  outlined
                  small
                >
                  <v-icon color="primary">mdi-download</v-icon> Descargar
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: null,
    docs: [],
    loading: true,
    downloadingFile: {},
  }),
  methods: {
    async getDocuments() {
      try {
        this.loading = true;
        const response = await this.$store.state.currentService.getBiometricSignedDocuments(
          this.$route.params.id
        );
        this.docs = response.data;
        for (const doc of this.docs) {
          this.$set(this.downloadingFile, doc.id, null);
        }
      } catch (e) {
        this.$notifyError(undefined, "Error al recuperar los archivos | " + e);
      } finally {
        this.loading = false;
      }
    },
    async downloadSessionFile(docId) {
      try {
        this.downloadingFile[docId] = true;
        const response = await this.$store.state.currentService.getFile(
          this.$route.params.id,
          docId
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const headerLine = response.headers["content-disposition"];
        const startFileNameIndex = headerLine.indexOf('"') + 1;
        const endFileNameIndex = headerLine.lastIndexOf('"');
        const filename = headerLine.substring(startFileNameIndex, endFileNameIndex);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${filename}`);
        link.setAttribute("target", "_doc");
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (e) {
        this.$notifyError(undefined, "Error al descargar el documento | " + e);
      } finally {
        this.downloadingFile[docId] = false;
      }
    },
  },
  watch: {
    dialog(val) {
      if (val) this.getDocuments();
    },
  },
};
</script>

<style scoped></style>
